import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';

import { BROWSER_EXTENSION_CUSTOM_EVENT_TYPES } from '@@core/models/events.model';
import { BrowserExtensionTokenData, Token } from '@@core/models/tokens.model';
import { APP_CONFIG } from '@@shared/providers/application-config-provider/application-config-provider.model';

@Injectable({
	providedIn: 'root'
})
export class BrowserExtensionService {
	readonly #apiBaseUrl = inject(APP_CONFIG).uri.apiBaseUrl;
	#tokenData: BrowserExtensionTokenData | null;
	readonly #storePage = `https://chrome.google.com/webstore/detail/sealights/${inject(APP_CONFIG).browserExtension.id}`;
	readonly #browserExtensionConfig = inject(APP_CONFIG).browserExtension;
	readonly #httpClient = inject(HttpClient);

	constructor() {
		this.init();
	}

	get tokenData(): BrowserExtensionTokenData | null {
		return this.#tokenData;
	}

	set tokenData(data: BrowserExtensionTokenData) {
		this.#tokenData = data;
	}

	init(): void {
		addEventListener(BROWSER_EXTENSION_CUSTOM_EVENT_TYPES.ReceiveToken, (response: CustomEvent) => {
			const tokenData = response.detail as BrowserExtensionTokenData;

			this.tokenData = tokenData;
		});
	}

	async isInstalled(): Promise<boolean> {
		return this.#isResourceAvailable(`chrome-extension://${this.#browserExtensionConfig.id}/${this.#browserExtensionConfig.resourceToCheck}`);
	}

	requestToken(): void {
		const startManualTestEvent = new CustomEvent(BROWSER_EXTENSION_CUSTOM_EVENT_TYPES.RequestToken);

		this.tokenData = null;
		dispatchEvent(startManualTestEvent);
	}

	async setToken(token?: Token): Promise<void> {
		let newToken = token;

		newToken ??= await firstValueFrom(this.#addExtensionToken(`remotely created token ${new Date().valueOf().toString()}`));

		const setTokenEvent = new CustomEvent(BROWSER_EXTENSION_CUSTOM_EVENT_TYPES.SetToken, { detail: newToken.token });
		dispatchEvent(setTokenEvent);
	}

	startManualTest(): void {
		const startManualTestEvent = new CustomEvent(BROWSER_EXTENSION_CUSTOM_EVENT_TYPES.StartManualTest);

		dispatchEvent(startManualTestEvent);
	}

	async isStorePageAvailable(): Promise<boolean> {
		return this.#isResourceAvailable(this.#storePage);
	}

	visitStore(): void {
		window.open(this.#storePage, '_blank');
	}

	async #isResourceAvailable(resource: string): Promise<boolean> {
		let found = false;

		await fetch(resource, { mode: 'no-cors' })
			.then(res => {
				found = res.status === 200 || res.type === 'opaque';
			})
			.catch(() => {
				console.error(`failed to access ${resource}`);
			});

		return found;
	}

	#addExtensionToken(name: string): Observable<Token> {
		const url = `${this.#apiBaseUrl}v1/tokens/integration`;

		const request = {
			name
		};

		return this.#httpClient.post<Token>(url, request);
	}
}
